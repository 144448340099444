<template>
  <div class="form--group row unit--detail-section">
    <div class="col-12 col-lg-3">
      <span>{{ $t('listing.unitDetail') }}</span>
      <span class="required">*</span>
    </div>
    <div class="col-12 col-lg-6">
      <div class="row my-0 unit--detail-row">
        <div
          class="col-6 py-0 single--field"
          :class="validation.hasError('bedroom') ? 'has-error' : ''"
          v-if="bedroomRule.allowed"
        >
          <v-text-field
            :label="$t('listing.placeholder.bedroom')"
            outlined
            :hide-details="true"
            type="number"
            v-model="bedroom"
            name="bedroom"
            :class="validation.hasError('bedroom') ? 'is-invalid' : ''"
            :readonly="readOnly"
          />
          <span class="val-error" v-if="validation.hasError('bedroom')">{{
            validation.firstError('bedroom')
          }}</span>
        </div>
        <div
          class="col-6 py-0 single--field"
          :class="validation.hasError('bathroom') ? 'has-error' : ''"
          v-if="bathroomRule.allowed"
        >
          <v-text-field
            :label="$t('listing.placeholder.bathroom')"
            outlined
            :hide-details="true"
            type="number"
            v-model="bathroom"
            name="bathroom"
            :class="validation.hasError('bathroom') ? 'is-invalid' : ''"
            :readonly="readOnly"
          />
          <span class="val-error" v-if="validation.hasError('bathroom')">{{
            validation.firstError('bathroom')
          }}</span>
        </div>
        <div
          class="col-6 py-0 single--field"
          :class="validation.hasError('buildingSize') ? 'has-error' : ''"
          v-if="buildingSizeRule.allowed"
        >
          <v-text-field
            :label="$t('listing.placeholder.buildingSize')"
            outlined
            :hide-details="true"
            type="number"
            v-model="buildingSize"
            name="building_size"
            :class="validation.hasError('buildingSize') ? 'is-invalid' : ''"
            :readonly="readOnly"
          />
          <span class="val-error" v-if="validation.hasError('buildingSize')">{{
            validation.firstError('buildingSize')
          }}</span>
        </div>
        <div
          class="col-6 py-0 single--field"
          :class="validation.hasError('landSize') ? 'has-error' : ''"
          v-if="landSizeRule.allowed"
        >
          <v-text-field
            :label="$t('listing.placeholder.landSize')"
            outlined
            :hide-details="true"
            type="number"
            v-model="landSize"
            name="land_size"
            :class="validation.hasError('landSize') ? 'is-invalid' : ''"
            :readonly="readOnly"
          />
          <span class="val-error" v-if="validation.hasError('landSize')">{{
            validation.firstError('landSize')
          }}</span>
        </div>
        <div
          class="col-6 py-0 single--field"
          :class="validation.hasError('totalFloor') ? 'has-error' : ''"
          v-if="totalFloorRule.allowed"
        >
          <v-text-field
            :label="$t('listing.placeholder.totalFloor')"
            outlined
            :hide-details="true"
            type="number"
            v-model="totalFloor"
            name="total_floor"
            :class="validation.hasError('totalFloor') ? 'is-invalid' : ''"
            :readonly="readOnly"
          />
          <span class="val-error" v-if="validation.hasError('totalFloor')">{{
            validation.firstError('totalFloor')
          }}</span>
        </div>
        <div
          class="col-6 py-0 single--field"
          :class="validation.hasError('floor') ? 'has-error' : ''"
          v-if="floorRule.allowed"
        >
          <v-text-field
            :label="$t('listing.placeholder.floor')"
            outlined
            :hide-details="true"
            v-model="floor"
            name="floor"
            :class="validation.hasError('floor') ? 'is-invalid' : ''"
            :readonly="readOnly"
          />
          <span class="val-error" v-if="validation.hasError('floor')">{{
            validation.firstError('floor')
          }}</span>
        </div>
        <div
          class="col-6 py-0 single--field"
          :class="validation.hasError('electricalPower') ? 'has-error' : ''"
          v-if="electricalPowerRule.allowed"
        >
          <v-text-field
            :label="$t('listing.placeholder.electricalPower')"
            outlined
            :hide-details="true"
            v-model="electricalPower"
            name="electrical_power"
            :class="validation.hasError('electricalPower') ? 'is-invalid' : ''"
            :readonly="readOnly"
          />
          <span class="val-error" v-if="validation.hasError('electricalPower')">{{
            validation.firstError('electricalPower')
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'unit-numbering-section',
  props: ['readOnly'],
  computed: {
    ...mapState({
      selectedPropertyType: (state) => state.listing.form.selectedPropertyType,
    }),
    ...mapGetters({
      floorRule: 'listing/form/floorRule',
      bedroomRule: 'listing/form/bedroomRule',
      bathroomRule: 'listing/form/bathroomRule',
      landSizeRule: 'listing/form/landSizeRule',
      totalFloorRule: 'listing/form/totalFloorRule',
      buildingSizeRule: 'listing/form/buildingSizeRule',
      electricalPowerRule: 'listing/form/electricalPowerRule',
    }),
    bedroom: {
      get() {
        return this.$store.state.listing.form.bedroom;
      },
      set(value) {
        return this.$store.commit('listing/form/SET_BEDROOM', value);
      },
    },
    bathroom: {
      get() {
        return this.$store.state.listing.form.bathroom;
      },
      set(value) {
        return this.$store.commit('listing/form/SET_BATHROOM', value);
      },
    },
    buildingSize: {
      get() {
        return this.$store.state.listing.form.buildingSize;
      },
      set(value) {
        return this.$store.commit('listing/form/SET_BUILDING_SIZE', value);
      },
    },
    landSize: {
      get() {
        return this.$store.state.listing.form.landSize;
      },
      set(value) {
        return this.$store.commit('listing/form/SET_LAND_SIZE', value);
      },
    },
    totalFloor: {
      get() {
        return this.$store.state.listing.form.totalFloor;
      },
      set(value) {
        return this.$store.commit('listing/form/SET_TOTAL_FLOOR', value);
      },
    },
    floor: {
      get() {
        return this.$store.state.listing.form.floor;
      },
      set(value) {
        return this.$store.commit('listing/form/SET_FLOOR', value);
      },
    },
    electricalPower: {
      get() {
        return this.$store.state.listing.form.electricalPower;
      },
      set(value) {
        return this.$store.commit('listing/form/SET_ELECTRICAL_POWER', value);
      },
    },
  },
  methods: {
    validate: function () {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
  watch: {
    selectedPropertyType() {
      this.validation.reset();
    },
  },
  validators: {
    bedroom(value) {
      if (!this.bedroomRule.allowed || !this.bedroomRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.addListing.bedroom.required'));
    },
    bathroom(value) {
      if (!this.bathroomRule.allowed || !this.bathroomRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.addListing.bathroom.required'));
    },
    buildingSize(value) {
      if (!this.buildingSizeRule.allowed || !this.buildingSizeRule.required) return null;
      return Validator.value(value).required(
        this.$i18n.t('errors.addListing.buildingSize.required'),
      );
    },
    landSize(value) {
      if (!this.landSizeRule.allowed || !this.landSizeRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.addListing.landSize.required'));
    },
    totalFloor(value) {
      if (!this.totalFloorRule.allowed || !this.totalFloorRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.addListing.totalFloor.required'));
    },
    floor(value) {
      if (!this.floorRule.allowed || !this.floorRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.addListing.floor.required'));
    },
    electricalPower(value) {
      console.log('asdada,', this.electricalPowerRule);
      if (!this.electricalPowerRule.allowed || !this.electricalPowerRule.required) {
        console.log('tidak required');
        return null;
      }
      return Validator.value(value).required(
        this.$i18n.t('errors.addListing.electricalPower.required'),
      );
    },
  },
};
</script>

<style scoped></style>
